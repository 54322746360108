import React, { useMemo, useEffect, useState } from 'react';
import queryString from 'qs';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import Setting from './setting';
import UserList from './userList';
import UTCTimer from './UTCTimer';
import './index.scss';

const UserNavigation = observer(({ className, isDisplay }) => {
  const { userStore } = useStores();
  let location = useLocation();
  const { t } = useTranslation();
  const [path, setPath] = useState('');

  const {
    isUserReadOnly,
    accountInfo,
    isSmartPool,
    hasSubAccount,
    isWatcher,
    isAuthenticated,
    hasUserAuthorityForEarnings,
    hasUserAuthorityForMiners,
    hasUserAuthorityForGetCoin,
  } = userStore;

  useEffect(() => {
    let path = location.pathname;
    setPath(path);
  }, [location]);

  // 观察者、共享者，通过url判断
  const search = useMemo(() => {
    const { isWatcher, isShared, isUserReadOnly } = userStore;
    let search = '';
    let searchParams = {};
    searchParams = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (isUserReadOnly) {
      if (isWatcher) {
        if (searchParams.access_key) {
          search = queryString.stringify(
            { access_key: searchParams.access_key, puid: searchParams.puid },
            { addQueryPrefix: true },
          );
        }
      } else if (isShared) {
        if (searchParams.puid) {
          search = queryString.stringify(
            { puid: searchParams.puid, shared: searchParams.shared },
            { addQueryPrefix: true },
          );
        }
      }

      return search;
    } else if (accountInfo.puid) {
      // 正常模式下，url携带当前puid
      search = queryString.stringify(
        { puid: accountInfo.puid },
        { addQueryPrefix: true },
      );
    }

    return search;
  }, [location, userStore, accountInfo]);
  const authNavs = useMemo(() => {
    let isShowMergeMining = hasUserAuthorityForGetCoin;

    let isHideEarning = !hasUserAuthorityForEarnings;

    let isShowMinersPage = hasUserAuthorityForMiners;

    const fullNavs = [
      {
        title: t('common:header.dashboard'),
        url: '/dashboard',
        id: 'dashboard',
        isShow: true,
      },
      {
        title: t('common:header.miners'),
        url: '/miners',
        id: 'mill',
        isShow: isShowMinersPage,
      },
      {
        title: t('common:setting.menu.earingsHistory'),
        url: '/earning-history',
        id: 'earningHistory',
        isShow: !isHideEarning,
      },
      {
        title: t('common:header.getCoin'),
        url: '/merge/getCoin',
        id: 'getCoin',
        isShow: isShowMergeMining,
      },
      {
        title: t('common:setting.menu.address'),
        url: '/settings/address',
        id: 'address',
        isShow: !isUserReadOnly,
      },
    ];

    let allowNavs = fullNavs;

    if (isAuthenticated) {
      allowNavs = fullNavs.filter((nav) => {
        return !!nav.isShow;
      });
    }

    return allowNavs;
  }, [
    accountInfo,
    location,
    isSmartPool,
    isWatcher,
    isAuthenticated,
    hasUserAuthorityForGetCoin,
    hasUserAuthorityForEarnings,
    hasUserAuthorityForMiners,
    isUserReadOnly,
  ]);

  if (!isDisplay) return null;

  return (
    <div className="header-content">
      <div className="header-content-left">
        {/* 子账户列表 */}
        {hasSubAccount && <UserList />}
        {/* 导航菜单 */}
        <ul className={className}>
          {authNavs.map((item) => {
            let url = item.url;
            let isActive = url === path || path.indexOf(url) > -1;
            if (url === '/miners' && path.includes('/minerinfo')) {
              isActive = true;
            }
            if (
              url === '/settings/address' &&
              path.includes('/secondaddress')
            ) {
              isActive = true;
            }

            return (
              <li
                className={
                  isActive
                    ? 'relative header-nav-item header-nav-item-active'
                    : 'relative header-nav-item'
                }
                key={item.id}
              >
                <>
                  <Link
                    to={{
                      pathname: item.url,
                      search: search,
                    }}
                  >
                    {item.title}
                  </Link>
                </>
              </li>
            );
          })}
          {/* 设置按钮 */}
          {!isUserReadOnly && hasSubAccount && (
            <li>
              <Setting
                isActive={
                  path.indexOf('settings') > -1 && !path.includes('address')
                }
                key={accountInfo.puid}
                puid={accountInfo.puid}
                coinType={accountInfo.coin_type}
                isSmartPool={isSmartPool}
              />
            </li>
          )}
        </ul>
      </div>
      <UTCTimer />
    </div>
  );
});

export default UserNavigation;
